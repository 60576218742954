import React from 'react'
import css from '../header/Header.module.css'
import Logo from '../../assets/logo.png'
import {CgShoppingBag} from 'react-icons/cg'
import {GoThreeBars} from 'react-icons/go'
import { useState } from 'react'

const Header = () => {

    const [showmenu, setShowmenu]=useState(true)

    const toggle = ()=>{
        setShowmenu((showmenu)=>!showmenu)
    }
  return (
    <div className={css.container}>
        <div className={css.logo}>
            <img src={Logo} alt="" />
            <span>IsrarAmazon</span>
        </div>

        

        <div className={css.right}>
            <div className={css.bars} onClick={toggle}>
                <GoThreeBars/>
            </div>
            
                <ul className={css.menu} style={{display: showmenu? "inherit": 'none'}}>
                    <li>Collections</li>
                    <li>Brands</li>
                    <li>New</li>
                    <li>Sales</li>
                    <li>ENg</li>
                </ul>
            


            <input type="text" className={css.search} placeholder="search" />

            <CgShoppingBag className={css.cart}/>
        </div>
    </div>
  )
}

export default Header