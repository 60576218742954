import React from 'react'
import css from "../Testimonial/testimonial.module.css"
import Hero from '../../assets/testimonialHero.png'

import {TestimonialsData} from '../../data/testimonials'
import { SwiperSlide,Swiper } from 'swiper/react'


const Testimonial = () => {
  return (
    <div className={css.testimonials}>
        <div className={css.wrapper}>
            <div className={css.container}>

            <span>Top rated</span>
            <span>Seedily say has suitable disposal and boy. Exercise joy man children rejoiced</span>

            </div>

        <img src={Hero} alt="" />

        <div className={css.container}>
            <span>100K</span>
            <span>Happy Customers with us</span>
        </div>
        </div>

        <div className={css.reviews}>Reviews</div>
        
        <div className={css.carousel}>
            <Swiper slidesPerView={3}
            slidesPerGroup={1}
            spaceBetween={20}
            className={css.tcarousel}
            breakpoints={{
                856:{
                    slidesPerView:3
                },
                640:{
                    slidesPerView:2
                },
                0:{
                    slidesPerView:1
                }
            }}
            >
                {
                    TestimonialsData.map((test,i)=>(
                            <SwiperSlide>
                                <div className={css.testimonial}>
                                    <img src={test.image} alt="" />
                                    <span>{test.comment}</span>
                                    <hr/>
                                    <span>{test.name}</span>
                                </div>
                            </SwiperSlide>
                        
                    ))
                }
            </Swiper>
        </div>
         


    </div>
  )
}

export default Testimonial