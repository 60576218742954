import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import '../slider/slider.css'


//import swipeer styles

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { SliderProducts } from '../../data/products'

const Slider = () => {
    return (
        <div className="s-cont">
            <Swiper
            breakpoints={{
                640:{
                    slidesPerView:3,
                },
                0:{
                    slidesPerView:1,
                },
            }}
             modules={[Pagination,Navigation]} className="mySwiper" 
            navigation={true}
            loopFillGroupWithBlank={true}
             slidesPerView={3} spaceBetween={40} slidesPerGroup={1} loop={true}>
            {
                SliderProducts.map((slide, i) => {
                    const {name, details,price,img}=slide
                    return(
                    <SwiperSlide>
                      <div className="left-s">
                      <div className="name">
                            <span>{name}</span>
                            <span>{details}</span>
                        </div>
                        <span>{price}$</span>
                        <div>Shop now</div>
                      </div>
                      <img src={img} alt="product" className='img-p' />
                    </SwiperSlide>
                    )
                })
            }
            </Swiper>
        </div>
    )
}

export default Slider